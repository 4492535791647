import React from "react";
// import kws from "../images/clients/kws.png";
// import geps from "../images/clients/geps.png";
// import protergia from "../images/clients/protergia.png";

const clientImage = {
  height: "10rem",
  width: "auto",
  mixBlendMode: "colorBurn",
};

const Clients = () => {
  return <div className="mt-8 bg-gray-100"></div>;
};

export default Clients;
